import React from 'react';
import styled from 'styled-components'
import Navbar from './Navbar';
import homecontainerbackgrond from '../img/Homekf.jpeg'
import Sale from './Sale';
import Sintesi from './Sintesi';
import Maps from './Maps';
import { ScrollTo } from "react-scroll-to";
import { Link, Element , Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import { Helmet } from 'react-helmet'

const Container = styled.div`
width:100%;
background-color:black;


`

const HomeContainer = styled.div`
width:100%;
height:100vh;
background-color:black;
background-size:cover;
background-repeat:no-repeat;
background-image: url(${homecontainerbackgrond});


@media (max-width:500px) {
width: 100%;
}
`

const HeroContent = styled.div`
padding-top:100px;
width:100%;
color:white;
text-align:center;
margin: 0 auto;
grid-template-columns: repeat(1,auto);
display:grid;
font-family: 'Rubik', sans-serif;

@media (max-width:1200px) {
padding-top:50px;
padding-bottom:50px;
}
`
const Button = styled.button`
width:190px;
padding:20px;
border: 5px solid #EAE1E1;
box-sizing: border-box;
background:none;
color:white;
font-weight:500;
font-family: 'Rubik', sans-serif;
font-size:22px;
margin:20px;
transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
cursor:pointer;
&:hover{
    background:white;
    color: #170A3D;
    text-decoration: line-through #EAE1E1;
    transform: translateY(-10px);
}
@media (max-width:500px) {
    width:160px;
    font-size:18px;
    height:80px;
    margin-bottom:50px;
}
`

const Title = styled.div`
font-size:80px;
font-weight:bold;
max-width:800px;
margin:0 auto;
text-align:center;

@media (max-width:1200px) {
font-size:47px;
}

@media (max-width:500px) {
   font-size:42px;
}

`


const Home = () => {
    return ( 
        <Container>
            <Helmet title={"Palestra Keep Fit a Quartu Sant'Elena"}>
                <meta name="description" content="Palestra a Quartu Sant'Elena in Via danimarca numero 18" />
            </Helmet>
            <HomeContainer>
                <Navbar/>
                <HeroContent>
                    <Title>
                        <p>IL CENTRO FITNESS GIUSTO PER TE</p>
                    </Title>
                    <div>
                        <Button onClick={()=> (scroll.scrollTo(1000))}>Scopri di più</Button>  
                    </div>
                </HeroContent>
            </HomeContainer>
            <Sale/>
            <Sintesi/>
            <Maps/>
        </Container>
     );
}
 
export default Home;