import React from 'react';
import styled from 'styled-components'
import Navbar from './Navbar';
import sfondoquote from '../img/quote.png'
import freccia from '../img/arrow.png'
import { Link, Element , Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

const Container = styled.div`
width:100%;
color:white;
background: linear-gradient(180deg, rgba(0, 0, 0, 0.16) 0%, #000000 65.1%), linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${sfondoquote});
background-repeat: no-repeat;
background-size: cover;
font-family: 'Rubik',sans-serif;
margin: 0 auto;

@media (max-width: 1200px) {
height:fit-content;
}
`

const InfoQuote = styled.div`
padding-bottom:50px;
width:80%;
display:grid;
margin: 0 auto;
grid-gap:0px;
grid-template-columns:repeat(1,auto);
text-align:center;
justify-self:center;

`

const QuoteContainer = styled.div`
grid-template-columns: repeat(3,auto);
display:grid;
width:100%;

@media (max-width: 1200px) {
grid-template-columns: repeat(1,auto);
grid-gap:40px;
}
`

const Quota = styled.div`
background: linear-gradient(330.39deg, #70E758 -0.55%, rgba(43, 135, 129, 0.56) 142.98%);
box-shadow: 10px 10px 40px rgba(112, 231, 88, 0.3);
border-radius: 9px;
padding:15px;
width:200px;
justify-self:center;
height:200px;
display:grid;
grid-template-columns: repeat(1,auto);
grid-gap:50px;
`

const Wrapper = styled.div`
margin-top:50px;
width:100%;
display:grid;
grid-gap:25px;
`

const Freccia = styled.img`
justify-self:center;
text-align:center;
width:40px;
transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
&:hover{
    transform:translateY(-10px);
    background: #97FF4D;
    padding:10px;
}
`

const Quote = () => {
    return (
      <Container>
        <Navbar />
        <Wrapper>
          <InfoQuote>
            <h2 style={{ fontSize: "36px" }}>
              VIENI QUANDO VUOI 7 GIORNI SU 7
            </h2>
            <Freccia src={freccia} onClick={() => scroll.scrollTo(600)} />
            <h1 style={{ color: "#97FF4D", fontSize: "32px" }}>QUOTE OPEN</h1>
            <QuoteContainer>
              <Quota>
                <h3>MENSILE</h3>
                <h3>55.00€</h3>
              </Quota>
              <Quota>
                <h3>TRIMESTRALE</h3>
                <h3>149.00€</h3>
              </Quota>
              <Quota>
                <h3>SEMESTRALE: 289.00€</h3>
                <h3>ANNUALE: 575.00€</h3>
              </Quota>
            </QuoteContainer>
          </InfoQuote>

          <InfoQuote>
              <h2 style={{ fontSize: "36px" }}>
QUOTE 2 GIORNI A SETTIMANA
            </h2>

            <h1 style={{ color: "#97FF4D", fontSize: "32px" }}>A TUA SCELTA</h1>

            <QuoteContainer>
              <Quota>
                <h3>MENSILE</h3>
                <h3>45.00€</h3>
              </Quota>
              <div></div>
              <Quota>
                <h3>TRIMESTRALE</h3>
                <h3>130.00€</h3>
              </Quota>
             
            </QuoteContainer>
          </InfoQuote>

           <InfoQuote>
            <h2 style={{ fontSize: "36px" }}>
              QUOTE 3 GIORNI A SETTIMANA
            </h2>

            <h1 style={{ color: "#97FF4D", fontSize: "32px" }}>MARTEDÌ GIOVEDÌ SABATO</h1>

            <QuoteContainer>
              <Quota>
                <h3>MENSILE</h3>
                <h3>50.00€</h3>
              </Quota>
              <div></div>
              <Quota>
                <h3>TRIMESTRALE</h3>
                <h3>140.00€</h3>
              </Quota>
             
            </QuoteContainer>
          </InfoQuote>

          <InfoQuote>
            <h2 style={{ fontSize: "36px" }}>
                SINGOLI INGRESSI
            </h2>
            <Freccia src={freccia} onClick={() => scroll.scrollTo(1400)} />
            <h2 style={{ color: "#97FF4D", fontSize: "32px" }}>TICKET DAY</h2>
            <QuoteContainer>
              <Quota>
                <h3>1 TICKET di Prova</h3>
                <h3>15.00€</h3>
              </Quota>
              <Quota>
                <h3>1 TICKET</h3>
                <h3>12.00€</h3>
              </Quota>
              <Quota>
                <h3>5 TICKET + 1 Omaggio</h3>
                <h3>55.00€</h3>
              </Quota>
            </QuoteContainer>
          </InfoQuote>

          <InfoQuote>
            <h2 style={{ fontSize: "36px" }}>
              ESEGUI SESSIONI PRIVATE DI PERSONAL TRAINING
            </h2>
            <Freccia src={freccia} onClick={() => scroll.scrollTo(0)} />
            <h2 style={{ color: "#97FF4D", fontSize: "32px" }}>
              SESSIONI PERSONAL
            </h2>
            <QuoteContainer>
              <div></div>
              <Quota>
                <h3>1 SESSIONE PERSONAL</h3>
                <h3>35.00€</h3>
              </Quota>
              <div></div>
            </QuoteContainer>
          </InfoQuote>
        </Wrapper>
      </Container>
    );
}
 
export default Quote;
