import React from 'react';
import styled from 'styled-components'
import schede from '../img/schede.png'
import orari from '../img/orari.png'
import technogym from '../img/attrezzi.png'
import freccia from '../img/frecciagiu.png'

const SintesiContainer = styled.div`
width:100%;
background-color:#70E758;
display:grid;
grid-template-columns: repeat(1,auto);
margin: 0 auto;
text-align:center;
font-family: 'Rubik', sans-serif;
padding-bottom:100px; 
padding-top:50px; 
position:relative;
`
const ItemsContainer = styled.div`
width:80%;
display:grid;
grid-template-columns: repeat(3,auto);
margin:0 auto;

@media (max-width:700px) {
grid-gap:80px;
grid-template-columns: repeat(1,auto);
}
`

const Item = styled.div`
display:grid;
width:300px;
grid-template-columns: repeat(1,auto);
text-align:center;
margin:0 auto;
@media (max-width:500px) {
grid-gap:40px;
}
`

const ItemImage = styled.img`
max-width:300px;
text-align:center;
justify-self:center;
`

const Freccia = styled.div`
width:100%;
position:absolute;
margin:0 auto;
bottom:-80px;
color:black;
`

const Sintesi = () => {
    return ( 
        <SintesiContainer>
            <h1 style={{color:'black'}}> PERCHE' KEEP FIT?</h1>
            <ItemsContainer>
                <Item>
                    <p>Piani personalizzati</p>
                    <ItemImage src={schede}/>
                </Item>
                <Item>
                    <p>Allestimenti all'avanguardia</p>
                    <ItemImage src={technogym}/>
                </Item>
                <Item>
                    <p>Operativi 7 giorni su 7</p>
                    <ItemImage src={orari}/>
                </Item>
            </ItemsContainer>
            <Freccia>
                <img src={freccia}></img>
            </Freccia> 
        </SintesiContainer>
     );
}
 
export default Sintesi;