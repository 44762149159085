/* eslint-disable react-hooks/rules-of-hooks */
import React,  {useState} from 'react';
import styled from 'styled-components'
import homecontainerbackgrond from '../img/Homekf.jpeg'
import logo from '../img/KfLogoIllustr.svg'
import * as yup from 'yup'
import {Formik} from 'formik'
import {gql} from 'apollo-boost'
import {useMutation } from '@apollo/react-hooks'
import { Link } from 'react-router-dom';
import bcrypt from 'bcryptjs'


const FORGOT = gql`
mutation ForgotPassword($email:String!){
    forgotPassword(email:$email) {
      email
    }
  }
`

const ReviewSchema = yup.object({
    email: yup.string().email('inserire un email valida').required('Questo campo non può essere lasciato vuoto')
});
const PasswordSchema = yup.object({
    psw: yup.string().min(6,'Inserire un minimo di 6 caratteri').required('Questo campo non può essere lasciato vuoto')
});


const forgotPassword = ({match}) => {
    const [sendEmail,obj_sendEmail] = useMutation(FORGOT);
    const [error,setError] = useState('');
    const [submitted,setSubmit] = useState(false);
    const [status,setStatus] = useState();
    const token = match.params.token;

    function response(stats) {
        setStatus(stats)
        console.log(stats)
        console.log(status)
    }

    async function send_data(psw,tkn) {
        const hashed_psw = await bcrypt.hash(psw,10)
        if (hashed_psw && tkn) {
            fetch('https://keepfitbackend.2xe45.admin.app.marcomoi.it/user/forgot-password', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: {
                    value: tkn,
                    },
                    npsw: {
                    value: hashed_psw
                    }
                }),
                }).then((res) => response(res.ok))
            }
        }


    if(token && status === undefined){
            return ( 
                <HomeContainer>
                    <Formik
                        initialValues={{psw:''}}
                        validationSchema={PasswordSchema}
                        onSubmit={(values,actions) => {
                            send_data(values.psw,token)
                        }}
                        >
                        {(props) => (
                            <div style={{width:"100%"}}>
                                <LogoStyled />
                                <Input type="password" placeholder="Nuova Password" onChange={props.handleChange('psw')}/> <br/>
                                <p style={{color:"red"}}>{error}</p>
                                <p style={{color:"red"}}>{props.errors.psw}</p>
                                <Button onClick={() => props.handleSubmit()} type="submit">Cambia Password</Button>
                            </div>
                        )}
                    </Formik>
                </HomeContainer>
            );
    }

    if (status) {
        return(
            <HomeContainer>
                <LogoStyled />
                <h1 style={{color:"green"}}>Password Cambiata con successo!</h1>
                <Link to="/"><Button>Torna Indietro</Button></Link>
            </HomeContainer>
        );
    }

    if (status === false) {
        return(
            <HomeContainer>
                <LogoStyled />
                <h1 style={{color:"red"}}>Il tuo link è scaduto!</h1>
                <Link to="/"><Button>Torna Indietro</Button></Link>
            </HomeContainer>
        );
    }

    const onError = (err) => {
        setError(String(err.message).replace('GraphQL error:', '').trim())
    }

    if (submitted) {
        return(
            <HomeContainer>
                <LogoStyled />
                <h1 style={{color:"white"}}>Email inviata a {obj_sendEmail.data.forgotPassword.email}</h1>
                <p style={{color:"green"}}>Controllare nella cartella SPAM</p>
                <Link to="/"><Button>Torna Indietro</Button></Link>
            </HomeContainer>
        );
    }

    return ( 
        <HomeContainer>
            <Formik
                initialValues={{email:''}}
                validationSchema={ReviewSchema}
                onSubmit={(values,actions) => {
                      sendEmail({variables:{
                         email:values.email
                        }}).then((res) => (console.log(res),setSubmit(true))).catch((err) => (onError(err)))
                }}
                >
                {(props) => (
                    <div style={{width:"100%"}}>
                        <LogoStyled />
                        <Input placeholder="Email" onChange={props.handleChange('email')}/> <br/>
                        <p style={{color:"red"}}>{error}</p>
                        <p style={{color:"red"}}>{props.errors.email}</p>
                        <Button onClick={() => props.handleSubmit()} type="submit">Recupera Password</Button>
                    </div>
                )}
            </Formik>
        </HomeContainer>
    );
}

const LogoStyled = styled.div`
height:60px;

margin: 0 auto;
width:60px;
text-align:center;
background:url(${logo});
background-repeat:no-repeat;
padding:20px;
margin-top:15px;
margin-bottom:15px;
z-index:99;
transition:  1s cubic-bezier(0.075, 0.82, 0.165, 1);
cursor:pointer;
&:hover{
transform: translateY(10px);
}
`

const Input = styled.input`
width:300px;
margin-top:20px;
margin-bottom:20px;
padding:20px;
background-color:white;
font-size:14px;
`
const Button = styled.button`
width:250px;
padding:20px;
border: 5px solid #EAE1E1;
box-sizing: border-box;
background:none;
color:white;
font-weight:500;
font-family: 'Rubik', sans-serif;
font-size:18px;
margin:20px;
transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
cursor:pointer;
&:hover{
    background:white;
    color: #170A3D;
    text-decoration: line-through #EAE1E1;
    transform: translateY(-10px);
}
@media (max-width:500px) {
    width:240px;
    font-size:18px;
    height:80px;
    margin-bottom:50px;
}
`

const HomeContainer = styled.div`
width:100%;
height:100vh;
margin:0 auto;
text-align:center;
justify-items:center;
align-content:center;
align-items:center;
display:grid;
grid-template-columns: repeat(1,auto);
background-color:black;
background-size:cover;
background-repeat:no-repeat;
background-image: url(${homecontainerbackgrond});
`
export default forgotPassword;
