import React from 'react';
import styled from 'styled-components'
import logo from '../img/KfLogoIllustr.svg'
import { Link } from 'react-router-dom'

const LogoStyled = styled.div`
height:80px;
background:url(${logo});
background-repeat:no-repeat;
padding:20px;
margin-top:15px;
z-index:99;
transition:  1s cubic-bezier(0.075, 0.82, 0.165, 1);
cursor:pointer;
&:hover{
transform: translateY(10px);
}
`
const Wrapper = styled.div`
display:grid;
text-align:center;
margin:0 auto;
width:89%;
grid-template-columns: repeat(2,auto);
@media (max-width:1200px) {
display:grid;
grid-template-columns: repeat(1,auto);
}
`

const Nav = styled.div`
display:flex;
flex-direction:row;
margin:0 auto;
@media (max-width:1200px) {
margin-top:40px;
display:grid;
grid-template-columns: repeat(1,auto);
left:0px;
width:fit-content;
margin-left:-40px;
grid-gap:20px;
}
`

const NavLink = styled.div`
font-family: 'Rubik', sans-serif;
font-style: normal;
font-weight: lighter;
font-size: 24px;
line-height: 22px;
/* identical to box height */
text-align: center;
letter-spacing: 0.06em;
color: #EAE1E1;
margin-top:20px;
padding:40px;
margin-left:30px;
height:fit-content;
cursor:pointer;
text-align:left;
transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
&:hover{
    background:white;
    color:#170A3D;
    transform:translateY(5px);
    text-decoration: line-through #EAE1E1;
    
}
@media (max-width:1200px) {
display:grid;
grid-template-columns: repeat(1,auto);
padding:10px;
}
`





const Navbar = () => {
    return(
        <div>
            <Wrapper>
            <div>
                <Link to='/'><LogoStyled src={logo}/></Link>
            </div>
            <Nav >
                <Link to='/chisiamo' style={{textDecoration:'None'}}><NavLink >Chi siamo</NavLink></Link>
                <Link to='/quote' style={{textDecoration:'None'}}><NavLink>Quote</NavLink></Link>
                <Link to='/contatti' style={{textDecoration:'None'}}><NavLink>Contatti</NavLink></Link> 
                <Link to='/app' disabled style={{textDecoration:'None'}}><NavLink style={{backgroundColor:"lime",color:"black",fontWeight:"600"}}>APP KEEP FIT</NavLink></Link> 
            </Nav>
            </Wrapper>
        </div>
    )
}


export default Navbar