import React from 'react';
import styled from 'styled-components'
import salacardio from '../img/Homekf.png'
import Navbar from './Navbar';
import {MailOption, Facebook, Phone , MapLocation} from 'grommet-icons'
const Container = styled.div`
width:100%;
color:white;
min-height:100vh;
background: linear-gradient(180deg, rgba(0, 0, 0, 0.16) 0%, #000000 65.1%), linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${salacardio});
background-repeat: no-repeat;
background-size: cover;
font-family: 'Rubik',sans-serif;
margin: 0 auto;

@media (max-width: 1200px) {
height:fit-content;
}
`

const ContactsContainer = styled.div`
width:60%;
grid-template-columns: repeat(2,auto);
display:grid;
grid-gap:40px;
margin:0 auto;
margin-top:50px;
padding-bottom:50px;

@media (max-width:1200px) {
grid-template-columns: repeat(1,auto);
padding-bottom:150px;
}
`

const Contact = styled.div`
background: linear-gradient(205.08deg, rgba(32, 34, 31, 0.987135) -7.09%, rgba(0, 0, 0, 0.81) 122.87%);
border: 8px solid white;
box-sizing: border-box;
width:300px;
height:300px;
text-align:center;
justify-content:center;
align-content:center;
justify-self:center;
cursor:pointer;
display:grid;
transition: 0.9s cubic-bezier(0.075, 0.82, 0.165, 1);

&:hover{
    transform:translateY(-10px);
    background: #97FF4D;
}

`
const emailme = "info@palestrakeepfit.com"
const locationkeepfit = "https://www.google.com/maps/place/Keep+Fit/@39.2358893,9.179907,14z/data=!4m5!3m4!1s0x0:0x1eb5c6acae60e4ea!8m2!3d39.2385854!4d9.206484"
const paginafacebook = "https://www.facebook.com/keepfitit/"

const Contacts = () => {
    return ( 
        <Container>
            <Navbar/>
            <ContactsContainer>
                <Contact onClick={()=>{window.open("mailto:"+ emailme, "emailWindow")}} data-rel="external">
                    <MailOption size='150px' color='white' />
                </Contact>
                <Contact onClick={()=> {window.open("tel:070812645","_self")}}>
                    <Phone size='150px' color='white' />
                </Contact>
                <Contact onClick={()=> {window.open(paginafacebook, '_blank')}}>
                    <Facebook size='150px' color='white' />
                </Contact>
                <Contact onClick={()=> {window.open(locationkeepfit, '_blank')}}>
                    <MapLocation size='150px' color='white' />
                </Contact>
                
            </ContactsContainer>
        </Container>
     );
}
 
export default Contacts;