import React from 'react';
import styled from 'styled-components'
import salafunzionale from '../img/salafunzionale.jpeg'
import salapesi from '../img/salapesi.jpg'
import salacardio from '../img/salacardio.jpeg'

const SaleContainer = styled.div`
padding-top:50px;
width:100%;
display:grid;
grid-template-columns: repeat(1,auto);
margin:0 auto;
color:white;
grid-gap:20px;
padding-bottom:50px;

@media (max-width:500px) {
    margin-top:200px;
}

@media (max-width:1200px) {
    margin-top:200px;
}
`

const SalaSection = styled.div`
width: 80%;
display:grid;
margin:0 auto;
grid-template-columns: repeat(2,auto);


@media (max-width:1200px) {
grid-template-columns:repeat(1,auto);
grid-gap:20px;

}

@media (max-width:500px) {
grid-template-columns:repeat(1,auto);
margin:0 auto;
}
`

const DescSection = styled.div`
display:grid;
font-family: 'Rubik', sans-serif;
grid-template-columns: repeat(1);
width:100%;
justify-content:center;

@media (max-width:1200px) {
text-align:left;
grid-gap:0px;
grid-row:1;
max-width:auto;
}

@media (max-width:500px) {
width: fit-content;
}
`
const ImageSection = styled.img`
width:600px;
margin: 0 auto;
text-align:center;
@media (max-width:1200px) {
text-align:center;
grid-gap:0px;
width:100%;
}
@media (max-width:500px) {
justify-self:center;
text-align:center;
}


`
const Title = styled.div`
width:70%;
font-family: 'Rubik',sans-serif;
font-weight: lighter;
font-size:20px;
margin:0 auto;
text-align:left;
`

const Sale = () => {
    return ( 
        <SaleContainer>
            <SalaSection>
                <DescSection>
                    <h1>ZONA FUNZIONALE</h1>
                    <p style={{maxWidth:'400px',fontSize:'28px'}}>Realizzata con una pavimentazione speciale anti-trauma, questa zona è adeguatamente attrezzata con materiale professionale SIDEA nella quale si eseguono lavori con strumentazione non convenzionale ad una classica sala pesi, come le kettlebell, i trx, le slam-ball, le sand-bag, le swissball, le Bosu, gli skimmy e le rope.</p>
                </DescSection>

                <ImageSection src={salafunzionale}>
                    
                </ImageSection>
            </SalaSection>

            <SalaSection>
                <ImageSection src={salapesi}>
                    
                </ImageSection>
                <DescSection style={{textAlign:'right'}}>
                    <h1>SALA PESI</h1>
                    <p style={{maxWidth:'400px',fontSize:'28px'}}>Realizzata tenendo conto delle esigenze di tutti, dallo sportivo agonista al neofita, è completamente attrezzata con la moderna linea isotonica TECNOGYM SELECTION che riproduce le traiettorie fisiologiche del corpo al fine di dare una sensazione di naturale fluidità di movimento.</p>
                </DescSection>
            </SalaSection>

            <SalaSection>
                <DescSection>
                    <h1>ZONA CARDIO</h1>
                    <p style={{maxWidth:'400px',fontSize:'28px'}}>Composta dalla linea TECNOGYM EXCITE, la zona cardio è attrezzata con 11 strumenti cardio che permettono ai trainer di preparare programmi adeguati al fine di migliorare la capacità cardio vascolare dell’ utente.</p>
                </DescSection>
                <ImageSection src={salacardio}>
                     
                </ImageSection>
            </SalaSection>
        </SaleContainer>
     );
}
 
export default Sale;
