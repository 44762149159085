import React from 'react';
import styled from 'styled-components'
import Navbar from './Navbar';
import salafunzionale from '../img/salafunzionale.png'

const Container = styled.div`
width:100%;
color:white;
height:fit-content;
background: linear-gradient(180deg, rgba(0, 0, 0, 0.16) 0%, #000000 85.1%), linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${salafunzionale});

background-repeat: no-repeat;
background-size: cover;
font-family: 'Rubik',sans-serif;
margin: 0 auto;

@media (max-width: 1200px) {
height:fit-content;
}
`

const DescContainer = styled.div`
justify-items:center;
padding-top: 20px;
margin: 0 auto;
text-align:center;
justify-content:center;
width:80%;
display:grid;
grid-template-columns: repeat(1, auto);
padding-bottom:100px;
`


const Chisiamo = () => {
    return ( 
        <Container>
            <Navbar/>
            <DescContainer>
                <h1 style={{color:'#97FF4D',fontSize:'46px'}}>LA NOSTRA AZIENDA</h1>
                <p style={{fontSize:"26px",lineHeight:"1.3"}}>L&#39; azienda Keep Fit apre i battenti nel 2017 con
l’obiettivo di dare un nuovo approccio al lavoro psico-
fisico di carattere funzionale. E&#39; un centro fitness
moderno che nasce con l’idea di colmare un vuoto
professionale nel mercato del fitness partendo dal
valore della struttura.
Per questo l’attività non è ubicata in un angusto
seminterrato, illuminato artificialmente tutto il giorno
come la maggioranza dei centri fitness presenti sul
territorio circostante, ma è situata in una struttura che
dà l’idea di spazio, in cui la luce naturale del giorno può
essere sempre fruita.
Una locazione caratterizzata da 10 vetrate con vista
non occlusa da nessuna struttura adiacente, composta
al suo interno in 3 zone differenti.
Zona cardio: per questa zona l&#39; azienda ha scelto
TechnoGym, leader nel mondo, come fornitore di
attrezzature cardiofitness.
Zona sala pesi: per attrezzare la sala l'azienda ha
scelto di dare continuità e uniformità sempre all&#39;
azienda leader TechnoGym.
Zona funzionale:adeguatamente attrezzata con
materiale professionale SIDEA, azienda specializzata
nel settore functional, è realizzata con una
pavimentazione speciale anti-trauma, dove si eseguono
lavori con strumentazione non convenzionale ad una
classica sala pesi, come le kettlebell, i trx, le slam-ball,
le sand-bag, le swissball, le Bosu, gli skimmy e le rope.</p>
                <h1 style={{color:'#97FF4D',fontSize:'46px'}}>CHI SIAMO E COSA FACCIAMO</h1>
                <p style={{fontSize:"26px",lineHeight:"1.3"}}>L&#39; azienda è composta da trainers specializzati per
venire incontro alle esigenze di ogni singolo individuo.
Queste possono essere di carattere prestativo, perciò
finalizzate ad una preparazione fisica concentrando il
lavoro su aspetti quali la coordinazione, la stabilità ed
equilibrio del corpo.
Possono essere a carattere posturale, svolgendo un
lavoro finalizzato a migliorare la condizione psicofisica
di tutti quegli utenti afflitti da patologie o atteggiamenti
posturali scorretti, derivati da stili di vita sedentari o da
lavori fisicamente logoranti;
Ed ultimo, ma non per importanza, il benessere.
Pur non avendo particolari obiettivi l’utente sarà seguito
nel miglioramento delle normali abilità motorie al fine di
prevenire inestetismi e migliorare la propria condizione
fisica.</p>
                <h1 style={{color:'#97FF4D',fontSize:'46px'}}>LA NOSTRA MISSION</h1>
                <p style={{fontSize:"26px",lineHeight:"1.3"}}>L’ obiettivo dell&#39; azienda è portare il benessere alla
portata di tutti, dal professionista <br/>all &#39;agonista fino al
neofita, accompagnandovi in un cammino
personalizzato.</p>
            </DescContainer>
        </Container>
     );
}
 
export default Chisiamo;