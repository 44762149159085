import React from 'react';
import styled from 'styled-components'
import mappa from '../img/mappa3.jpeg'

const MapsContainer = styled.div`
width:100%;
height:680px;
background-color:black;
background-size:cover;
background-repeat:no-repeat;
background-image: url(${mappa});

@media (max-width:1200px) {
    height:fit-content;
}
@media (max-width:500px) {
    height:fit-content;
}
`

const InfoContainer = styled.div`
padding-top:200px;
display:grid;
grid-template-columns: repeat(1,auto);
color:white;
width:80%;
margin:0 auto;
text-align:center;
justify-self: center;
align-self: center;
grid-gap:80px;
@media (max-width:1200px) {
    padding-top:150px;
    padding-bottom:100px;
}
@media (max-width:500px) {
    height:fit-content;
    padding-bottom:50px;
}

`

const Button = styled.button`
width:290px;
justify-self:center;
padding:20px;
border: 5px solid #EAE1E1;
box-sizing: border-box;
background:none;
color:white;
font-weight:500;
font-family: 'Rubik', sans-serif;
font-size:22px;
margin:20px;
transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
cursor:pointer;
&:hover{
    background:white;
    color: #170A3D;
    text-decoration: line-through #EAE1E1;
    transform: translateY(-10px);
}
@media (max-width:500px) {
    width:200px;
    font-size:18px;
    height:80px;
    margin-bottom:50px;
}
`

const locationkeepfit = "https://www.google.com/maps/place/Keep+Fit/@39.2358893,9.179907,14z/data=!4m5!3m4!1s0x0:0x1eb5c6acae60e4ea!8m2!3d39.2385854!4d9.206484"

const Maps = ( ) => {
    return ( 
        <MapsContainer>
            <InfoContainer>
            <h1 style={{maxWidth:'600px',justifySelf:'center'}}>CI TROVI IN VIA DANIMARCA 18 QUARTU SANT’ELENA</h1>
            <Button onClick={()=> {window.open(locationkeepfit, '_blank')}}>Apri Google Maps</Button>
            </InfoContainer>
        </MapsContainer>
     );
}
 
export default Maps;