import React from 'react';
import Navbar from './components/Navbar';
import { BrowserRouter , Route , Switch } from 'react-router-dom';
import Home from './components/Home'
import Quote from './components/Quote';
import Contacts from './components/Contacts';
import Chisiamo from './components/Chisiamo';
import forgotPassword from './components/forgotPassword';
import { ApolloProvider } from 'react-apollo';
import {ApolloClient , HttpLink , InMemoryCache} from 'apollo-boost'
import Appkf from './components/Appkf';

const client = new ApolloClient({
  link: new HttpLink({ uri: 'https://keepfitbackend.2xe45.admin.app.marcomoi.it/graphql'}),
  cache: new InMemoryCache(),
});

function App() {
  return (
  <ApolloProvider client={client}>
      <BrowserRouter>
        <Switch>
            <Route path='/quote' component={Quote}/>
            <Route path='/chisiamo' component={Chisiamo}/>
            <Route path='/contatti' component={Contacts}/>
            <Route path='/news' component={Home}/>
            <Route path='/app' component={Appkf}/>
            <Route path='/forgot-password/:token' component={forgotPassword}/>
            <Route path='/forgot-password' component={forgotPassword}/>
            <Route path='/' component={Home}/>
        </Switch>
      </BrowserRouter>
    </ApolloProvider>
  );
}

export default App;
