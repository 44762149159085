import React from 'react';
import styled from 'styled-components'
import homecontainerbackgrond from '../img/Homekf.png'
import logo from '../img/KfLogoIllustr.svg'
import { Link } from 'react-router-dom';

const Appkf = () => {
    return ( 
        <HomeContainer>
            <LogoStyled/>
            <a href="https://apple.co/2wumUK1" target="_blank"><Button>iOS</Button></a>
            <a href="http://bit.ly/2P4stVN" target="_blank"><Button>Android</Button></a>
            <Link to="/forgot-password"><Button>Recupera Password</Button></Link>
        </HomeContainer>
     );
}


const LogoStyled = styled.div`
height:60px;

margin: 0 auto;
width:60px;
text-align:center;
background:url(${logo});
background-repeat:no-repeat;
padding:20px;
margin-top:15px;
margin-bottom:15px;
z-index:99;
transition:  1s cubic-bezier(0.075, 0.82, 0.165, 1);
cursor:pointer;
&:hover{
transform: translateY(10px);
}
`

const Input = styled.input`
width:300px;
margin-top:20px;
margin-bottom:20px;
padding:20px;
background-color:white;
font-size:14px;
`
const Button = styled.button`
width:250px;
padding:20px;
border: 5px solid #EAE1E1;
box-sizing: border-box;
background:none;
color:white;
font-weight:500;
font-family: 'Rubik', sans-serif;
font-size:18px;
margin:20px;
transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
cursor:pointer;
&:hover{
    background:white;
    color: #170A3D;
    text-decoration: line-through #EAE1E1;
    transform: translateY(-10px);
}
@media (max-width:500px) {
    width:240px;
    font-size:18px;
    height:80px;
    margin-bottom:50px;
}
`

const HomeContainer = styled.div`
width:100%;
height:100vh;
margin:0 auto;
text-align:center;
justify-items:center;
align-content:center;
align-items:center;
display:grid;
grid-template-columns: repeat(1,auto);
background-color:black;
background-size:cover;
background-repeat:no-repeat;
background-image: url(${homecontainerbackgrond});
`

export default Appkf;